define("@outdoorsyco/ember-shared-data/helpers/convert-time-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertTimeFormat = convertTimeFormat;
  _exports.default = void 0;

  function convertTimeFormat([time24h]) {
    if (!time24h) {
      return '';
    }

    const [hours, minutes] = time24h.split(':');
    return (0, _moment.default)({
      hours,
      minutes
    }).format('h:mm A');
  }

  var _default = Ember.Helper.helper(convertTimeFormat);

  _exports.default = _default;
});