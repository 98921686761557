define("@outdoorsyco/ember-shared-data/components/address-form/component", ["exports", "@outdoorsyco/ember-shared-data/components/address-form/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    geocoder: Ember.inject.service(),
    street1: null,
    street2: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    lat: null,
    long: null,
    shouldGeocode: true,
    error: false,
    disabled: false,
    geocoded: Ember.computed.and('lat', 'long'),
    geocodeReady: Ember.computed.and('city', 'state', 'street1', 'shouldGeocode'),
    geocodeAddress: (0, _emberConcurrency.task)(function* () {
      if (this.get('geocodeReady')) {
        // Reset
        this.setProperties({
          lat: null,
          long: null,
          error: false
        });
        let query = `${this.get('street1')} ${this.get('street2') || ''} ${this.get('city')}, ${this.get('state')} ${this.get('zip') || ''} ${this.get('country') || 'US'}`;

        try {
          let location = yield this.get('geocoder').geocode(query);
          this.setProperties({
            lat: Number(location[0].lat),
            long: Number(location[0].lon)
          });
        } catch (e) {
          this.set('error', true);
        }
      }
    }).drop().maxConcurrency(1).keepLatest(),
    actions: {
      mutateSelect(key, value) {
        this.set(key, value);
        this.get('geocodeAddress').perform();
      }

    }
  });

  _exports.default = _default;
});